import HeadMetaBuilder from "~/core/ts/util/HeadMetaBuilder"
import { translate } from "~/core/ts/util/translate"

export function getHomeMetadata(
  url: string,
) {
  const { pageHeadTitle, metaDescription, metaTitle } = getTitleAndDescription()

  const combtitle = pageHeadTitle

  const builder = HeadMetaBuilder.create()
    .withTitle(combtitle)
    .withMetaTitle(metaTitle)
    .withMetaDescription(metaDescription)
    .withOpenGraphMeta(combtitle, metaDescription)
    .withTwitterMeta(combtitle, metaDescription)
    .withLink('canonical', url)

  const imageUrl = 'https://marketplace-assets.s3.eu-west-3.amazonaws.com/2023/12/Captura-de-pantalla-2023-12-01-a-las-10.19.30.png'
  builder
    .withTwitterImage(imageUrl)
    .withOpenGraphImage(imageUrl)
    .withThumbnailImage(imageUrl)

  return builder.get()
}

export function getTitleAndDescription() {
  const title = translate({
    es: 'Spalopia - Reserva tratamientos de Balnearios y Spas de España',
    pt: 'Spalopia - Reserva de tratamentos em Spas e Termas em Portugal',
  })

  const metaDescription = translate({
    es: 'En Spalopia tienes una amplia selección de Spas y Balnearios para disfrutar de un momento wellness al mejor precio ✅ Regala Bienestar',
    pt : 'Na Spalopia tem uma vasta seleção de Spas e Spas Resorts para desfrutar de um momento de bem-estar ao melhor preço ✅ Ofereça o presente do Bem-estar',
  })

  const meta = {
    // Título usado en <title></title>
    pageHeadTitle: title,

    title: title,
    metaTitle: title,

    metaDescription,
  }

  return meta
}
