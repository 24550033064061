<template>
  <div class="newsletter-sub">
    <div class="newsletter-sub__image" />
    <div class="newsletter-sub__form">
      <form>
        <div class="newsletter-sub__form__title">
          Recibe un código de descuento del 5% para tu primera compra:
        </div>
        <div class="newsletter-sub__form__label">
          Suscríbete a nuestra Newsletter para obtener el descuento*
        </div>

        <div class="newsletter-sub__form__input-wrapper">
          <div class="newsletter-sub__form__input-wrapper__input">
            <SplInputBox
              id="newsletter-name"
              v-model="name"
              label="Nombre"
            />
          </div>

          <div class="newsletter-sub__form__input-wrapper__input">
            <SplInputBox
              id="newsletter-email"
              v-model="email"
              label="Email"
            />
          </div>

          <div>
            <SelectBox
              id="get-away-newsletter"
              variant="border"
              :data="provinces"
              display-prop="label"
              @input="selectProvince"
            >
              <template #label>
                <span>
                  {{ selectedProvinceLabel }}
                </span>
              </template>
            </SelectBox>
          </div>
        </div>

        <div class="newsletter-sub__form__input-wrapper">
          <SplCheckbox id="newsletter-conditions-1">
            <label
              class="gfield_consent_label"
              for="newsletter-conditions-1"
            >
              Acepto el <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/aviso-legal/"
                no-lang
                is-target-blank
              >
                Aviso Legal
              </SplNuxtLink>,
              la <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/politica-de-privacidad/"
                no-lang
                is-target-blank
              >Política de
                Privacidad</SplNuxtLink>
              y la <SplNuxtLink
                class="newsletter-sub__link"
                to="https://www.spalopia.com/politica-de-cookies/"
                no-lang
                is-target-blank
              >Política de Cookies
              </SplNuxtLink> </label>
          </SplCheckbox>

          <SplCheckbox id="newsletter-conditions-2">
            <label for="newsletter-conditions-2">Acepto recibir comunicaciones comerciales</label>
          </SplCheckbox>
        </div>

        <div class="newsletter-sub__form__button">
          <SplButton
            variant="primary"
            size="md"
            submit
          >
            Obtener descuento
          </SplButton>
        </div>

        <div class="newsletter-sub__form__label">
          *No aplica en servicios con descuentos ni en escapadas wellness
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import SplButton from '../shared/form/SplButton.vue'
import SplInputBox from '../shared/form/SplInputBox.vue'
import SplCheckbox from '../shared/form/SplCheckbox.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'

import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import type { Location } from '~/core/ts/entity/Location'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SplButton,
    SplInputBox,
    SplCheckbox,
    SelectBox,
  },
  async setup() {
    const provinces = await useFetchProxy<Location[]>(
      '/api/locations/locations-by-type',
      {
        method: 'post',
        body: {
          type: 'region'
        },
      },
    )

    const name = ref('')
    const email = ref('')

    const selectedProvince = ref<Location | null>(null)
    function selectProvince(province: Location) {
      selectedProvince.value = province
    }

    const selectedProvinceLabel = computed(() => {
      if (!selectedProvince.value) {
        return 'Provincia'
      }

      return selectedProvince.value.label
    })

    return {
      provinces,

      name,
      email,

      selectedProvinceLabel,
      selectProvince,
    }
  }
})

</script>

<style lang="scss" scoped>
.newsletter-sub {
  @apply flex;

  @apply border-1;
  @apply border-spl-primary-dark;

  @apply rounded-md;

  &__link {
    @apply text-spl-primary-dark;
    @apply font-semibold;
  }

  &__link:hover {
    @apply text-spl-primary;
  }

  &__image {
    @apply hidden;

    @screen md {
      @apply block;
      @apply w-200p;
    }

    @screen lg {
      @apply w-300p;
    }

    background-image: url('~/assets/images/slider/Slide2.webp');
    background-size: cover;
  }

  &__form {
    @apply flex-1;

    @apply p-4;

    @screen md {
      @apply p-6;
    }

    &__title {
      @apply text-2xl font-bold;
      @apply text-spl-primary;
    }

    &__label {
      @apply p-2;

      @apply text-spl-primary-dark;
    }

    &__input-wrapper {
      @apply py-2;
      &__input {
        @apply border-1;
      }
    }

    &__button {
      @apply my-2;
    }
  }
}
</style>
