<template>
  <div class="slide-show">
    <div
      :id="uuid"
    >
      <slot
        :element="currentElement"
        :index="currentIndex"
      >
        {{ currentElement }}
      </slot>
    </div>


    <button
      class="slide-show__next-button slide-show__next-button--left next-button"
      @click.stop.prevent="nextElement(-1)"
    >
      <ArrowIcon
        :size="14"
        variant="dark"
      />
    </button>

    <div class="slide-show__progress">
      <ProgressIndicator
        :amount="elements.length"
        :selected-item="currentIndex"
      />
    </div>

    <button
      class="slide-show__next-button slide-show__next-button--right next-button"
      @click.stop.prevent="nextElement(1)"
    >
      <ArrowIcon
        :size="14"
        variant="dark"
        direction="right"
      />
    </button>
  </div>
</template>

<script lang="ts">
import ArrowIcon from '~/core/components/shared/icons/Arrow.icon.vue'
import ProgressIndicator from './ProgressIndicator.vue'
import { useSwiper } from '~/core/composable/shared/useSwipper'

export default defineNuxtComponent({
  components: {
    ProgressIndicator,
    ArrowIcon,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    elements: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    displayControls: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const currentIndex = ref(0)

    const currentElement = computed(() => {
      return props.elements[currentIndex.value]
    })

    function nextElement(direction: number) {
      let newIndex = currentIndex.value + direction
      const lastIndex = props.elements.length - 1

      if (newIndex > lastIndex) {
        newIndex = 0
      }

      if (newIndex < 0) {
        newIndex = lastIndex
      }

      currentIndex.value = newIndex
    }


    const shouldDisplayControls = computed(() => {
      if (!props.displayControls) {
        return false
      }

      return props.elements.length > 1
    })

    const idLabel = computed(() => props.uuid)
    useSwiper(idLabel.value, {
      handleSwipeLeft: () => nextElement(-1),
      handleSwipeRight: () => nextElement(1),
    })

    return {
      idLabel,
      currentElement,
      currentIndex,

      shouldDisplayControls,

      nextElement,
    }
  },
})
</script>

<style lang="scss" scoped>
.slide-show {
  @apply h-full;
  @apply rounded-4xl;
  @apply relative;

  &__progress {
    @apply flex justify-center;
  }

  &__next-button {
    z-index: 10;

    @apply absolute;
    top: calc(50% - 20px);

    &--left {
      @apply left-6;
    }
    &--right {
      @apply right-6;
    }
  }
}

.next-button {
  @apply bg-spl-gray-1;
  @apply opacity-70;
  @apply rounded-full;

  @apply border-1;
  @apply border-spl-gray-2;

  @apply h-11;
  @apply w-11;
  transition: 0.25s;
  &:active {
    @apply bg-spl-secondary-dark;
  }

  @apply text-spl-dark;
  @apply flex justify-center items-center;
}
</style>
