<template>
  <div class="gift-card">
    <h2 class="gift-card__title">
      <Lang
        by-key="gift-card"
        capitalize-all
      />
    </h2>
    <div class="gift-card__wrapper">
      <div class="gift-card__wrapper__left-side">
        <p class="gift-card__subtitle">
          <Lang
            by-key="select-price"
            capitalize-first
          />
        </p>

        <div class="gift-card__slider">
          <span class="gift-card__slider__btn">
            <SplButton
              variant="round"
              @click="subValue"
            >
              -
            </SplButton>
          </span>

          <input
            v-model="cardValue"
            class="gift-card__slider__input"
            type="range"
            :min="min"
            :max="max"
            :step="10"
          >

          <span class="gift-card__slider__btn">
            <SplButton
              variant="round"
              @click="addValue"
            >
              +
            </SplButton>
          </span>
        </div>

        <div class="gift-card__value-display">
          <p class="gift-card__value-display__value">
            {{ cardValueStr }}
          </p>
        </div>

        <SplNuxtLink
          no-lang
          :to="targetURLPath"
          :query="targetURLQuery"
        >
          <SplButton
            variant="acent"
            size="lg-w-full"
            @click="onContinueClick"
          >
            <Lang
              by-key="continue"
              capitalize-first
            />
          </SplButton>
        </SplNuxtLink>
      </div>
      <div class="gift-card__wrapper__right-side">
        <div>
          <Lang
            by-key="gift-card"
            capitalize-all
          />
        </div>

        <div class="gift-card__wrapper__right-side__value">
          {{ cardValueStr }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import SplButton from '../shared/form/SplButton.vue'

import { Money } from '~/core/ts/entity/Money'
import LoadingEventBus from '~/core/events/loading'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SplButton,
  },
  setup() {
    const min = 10
    const max = 500

    const cardValue = ref(20)
    const cardValueStr = computed(() => {
      const currentValue = Number(cardValue.value)
      return Money.toString(Money.fromAmount(currentValue * 100), 0)
    })

    function addValue() {
      const currentValue = Number(cardValue.value)
      cardValue.value = Math.min(currentValue + 10, max)
    }

    function subValue() {
      const currentValue = Number(cardValue.value)
      cardValue.value = Math.max(currentValue - 10, min)
    }

    function onContinueClick() {
      LoadingEventBus.show()
    }

    const targetURLPath = 'https://www.spalopia.com/tarjeta-regalo/comprar/'
    const targetURLQuery = computed(() => {
      return {
        'utbgb_bundle': 'rfh-bono-gift-crad-default',
        'utbgb_context': 'rfh_gift_cards_spa',
        'utbgb_brand_id': '163-f2400000',
        'utbgb_id': cardValue.value.toString(),
      }
    })

    return {
      min,
      max,
      cardValue,
      cardValueStr,

      targetURLPath,
      targetURLQuery,

      addValue,
      subValue,
      onContinueClick,
    }
  }
})
</script>

<style lang="scss" scoped>
.gift-card {
  @apply p-8;

  &__title {
    @apply text-3xl;
    @apply font-bold;
    @apply text-spl-dark;

    @apply mb-4;
  }

  &__subtitle {
    @apply font-bold;
    @apply text-spl-dark;

    @apply text-lg;
    @apply mb-4;
  }

  &__wrapper {
    @apply flex;
    @apply justify-between;

    @apply flex-col;

    @apply gap-4;

    @screen md {
      @apply flex-row;
    }

    @screen lg {
      @apply gap-8;
    }

    &__left-side,
    &__right-side {
      @apply flex-1;

      @apply p-8;
    }

    &__right-side {
      @apply p-8;
      @apply flex;
      @apply flex-col;

      @apply gap-6;

      @apply justify-center;
      @apply items-center;

      @apply text-spl-white;
      @apply text-5xl;

      @apply rounded-4xl;

      background-image: url('~/assets/images/home/gift-card-image.jpg');
      background-size: cover;

      &__value {
        @apply text-6xl;
      }
    }
  }

  &__slider {
    @apply flex;
    @apply justify-center;

    @apply px-4;
    @apply gap-4;

    &__btn, &__input {
      @apply p-4;
    }

    &__btn {
      button {
        @apply bg-white;
      }
    }

    &__input {
      @apply flex-1;
    }

    // Generado en: https://www.cssportal.com/style-input-range/
    &__input {
      -webkit-appearance: none;
      @apply bg-transparent;
    }
    &__input:focus {
      outline: none;
    }
    &__input::-webkit-slider-runnable-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      @apply bg-spl-secondary;
      border-radius: 8px;
      border: 0px solid #208A5F;
    }
    &__input::-webkit-slider-thumb {
      border: 0px solid #000000;
      height: 32px;
      width: 32px;
      border-radius: 25px;
      @apply bg-spl-secondary-dark;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -12px;
    }
    &__input:focus::-webkit-slider-runnable-track {
      @apply bg-spl-secondary;
    }
    &__input::-moz-range-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      @apply bg-spl-secondary;
      border-radius: 8px;
      border: 0px solid #208A5F;
    }
    &__input::-moz-range-thumb {
      border: 0px solid #000000;
      height: 32px;
      width: 32px;
      border-radius: 25px;
      @apply bg-spl-secondary-dark;
      cursor: pointer;
    }
    &__input::-ms-track {
      width: 100%;
      height: 8px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &__input::-ms-fill-lower {
      @apply bg-spl-secondary;
      border: 0px solid #208A5F;
      border-radius: 16px;
    }
    &__input::-ms-fill-upper {
      @apply bg-spl-secondary;
      border: 0px solid #208A5F;
      border-radius: 16px;
    }
    &__input::-ms-thumb {
      margin-top: 1px;
      border: 0px solid #000000;
      height: 32px;
      width: 32px;
      border-radius: 25px;
      @apply bg-spl-secondary-dark;
      cursor: pointer;
    }
    &__input:focus::-ms-fill-lower {
      @apply bg-spl-secondary;
    }
    &__input:focus::-ms-fill-upper {
      @apply bg-spl-secondary;
    }
  }

  &__value-display {
    @apply flex;
    @apply justify-center;

    @apply p-4;
    @apply mb-4;

    &__value {
      @apply bg-white;
      @apply rounded-sm;

      @apply border-1;

      @apply px-14;
      @apply py-4;

      @apply text-lg;
    }
  }
}

/**


*/
</style>
